<template>
  <div class="background leaderboard-background"></div>
  <img src="../assets/images/logo_avis.png" class="logo" >
  <div class="row mx-0 position-relative justify-content-center">
    <div class="col-12 p-0 mb-3 text">
      TOP 10 LEADERBOARD
    </div>
    <div class="col-12 p-0">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">POSITION</th>
            <th scope="col">NAME</th>
            <th scope="col">POINTS</th>
            <th scope="col">TIMES (S)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(people, key) in leaderboard.slice(0, 10)" :key="key">
            <td scope="row">{{people.position}}.</td>
            <td class="text-uppercase">{{people.name}}</td>
            <td>{{people.points}}</td>
            <td>{{people.time}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 p-0" v-if="participantPosition">
      <p class="text-center">
        You placed No. {{ participantPosition }} on the leaderboard
      </p>
    </div>
    <div class="row mx-0 mt-3">
      <div class="col-12 p-0">
        <button @click="prize" class="arrow-button">
          <span class="arrow-icon">&gt;</span>
        </button>
      </div>
    </div>
    <!-- <div class="col-auto p-0 my-4">
      <Button @clicked="prize">
        <span class="d-none d-md-inline-block">Go to &nbsp;</span>prize generator
      </Button>
    </div> -->
  </div>
</template>

<script>
// import Button from '../components/Button.vue'
import { getLeaderboard } from '../api'
export default {
  // components: { Button },
  name: 'Leaderboard',
  data () {
    return {
      participantPosition: null,
      leaderboard: [
        {
          position: 1,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 2,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 3,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 4,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 5,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 6,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 7,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 8,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 9,
          name: '',
          points: '',
          time: ''
        },
        {
          position: 10,
          name: '',
          points: '',
          time: ''
        }
      ]
    }
  },
  async mounted () {
    const loader = this.$loading.show()
    try {
      const response = await getLeaderboard(this.$route.params.id)
      this.leaderboard = response.data.data.leaderboard
      this.participantPosition = response.data.data.position
      if (this.leaderboard.length < 10) {
        for (let index = this.leaderboard.length; index < 10; index++) {
          this.leaderboard.push({
            position: index + 1,
            name: '',
            points: '',
            time: ''
          })
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data)
      } else {
        alert(error.message)
      }
    }
    loader.hide()
  },
  methods: {
    prize () {
      this.$router.push(`/prizes/${this.$route.params.id}`)
    }
  }
}
</script>

<style scoped>

.text {
  color: #fff;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.table {
  color: #fff;
  font-size: 0.8rem;
}
.table th {
  color: #fff;
}
.absolute_bottom {
  position: fixed;
  bottom: 0.5rem;
  text-align: center;
}
.position-absolute {
  right: 5rem;
  z-index: 3;
}
.arrow-button {
  border: none;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}

.arrow-button:focus {
  outline: none;
}

.arrow-icon {
  display: inline-block;
  color: #d3202f;
  font-size: 24px; /* Change size as needed */
}
@media (min-width: 992px) {
  .absolute_bottom {
    bottom: 2rem;
  }
  .text {
    font-size: 2.5rem;
  }
  .table {
    color: #fff;
    font-size: 1rem;
  }
}
</style>
